import {RAILS_ENV} from '@tfx/common/constants.js'

function initializeTrustPilot() {
  if (RAILS_ENV !== 'production' && RAILS_ENV !== 'staging') return
  if (!document.getElementById('trustpilot-script')) {
    const script = document.createElement('script')
    script.id = 'trustpilot-script'
    script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
    script.async = true
    document.head.appendChild(script)
    script.onload = () => {
      loadTrustPilotWidget()
    }
  }
  document.addEventListener('turbolinks:load', () => {
    loadTrustPilotWidget()
  })
}

function loadTrustPilotWidget() {
  const trustpilotElements = document.querySelectorAll('.trustpilot-widget')
  trustpilotElements.forEach((element) => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(element, true)
    }
  })
}

export default initializeTrustPilot
