import {setGaUserId} from '@tfx/common/modules/externals-scripts'
import {getGeoAndClientData, clientData} from '@tfx/common/store/geolocation-clientdata'
import {setSentryUserId} from '@tfx/common/config/sentry'

export default {
  namespaced: true,
  actions: {
    getClientData({commit}) {
      getGeoAndClientData(() => {
        commit('ADD_CLIENT_DATA')
      })
    }
  },
  mutations: {
    ADD_CLIENT_DATA(_state) {
      if (clientData.value.user_id) {
        setTimeout(() => setGaUserId(clientData.value.user_id), 200)
        setSentryUserId(clientData.value.client_slug)
      }
    }
  },
  getters: {
    clientData: (_state) => clientData.value
  }
}
