import Http from 'vue-resource/src/http'

export class ArticlesCategoryPageComponent {
  constructor(el) {
    this.root = el.element
    this.articleCategoriesSelect = document.querySelector('[role=article-categories-select]')
    this.lastPage = false
    this.categoryId =this.root.getAttribute('data-id-category-articles')
    this.bindEvents()
  }

  bindEvents() {
    if (this.articleCategoriesSelect) {
      this.articleCategoriesSelect.addEventListener('change', (e) => this.redirectToSelectedCategory(e))
    }
  }

  redirectToSelectedCategory() {
    location.href = this.articleCategoriesSelect.options[this.articleCategoriesSelect.selectedIndex].value
  }
}
