// TODO:make it works

import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['content', 'icon']

  toggle(event) {
    event.preventDefault()

    const question = event.currentTarget
    const content = question.nextElementSibling
    const icon = question.querySelector("[data-faq-toggle-target='icon']")
    question.classList.toggle('tw-text-orange-500')
    content.classList.toggle('tw-hidden')
    if (icon.style.transform === 'rotate(180deg)') {
      icon.style.transform = 'rotate(0deg)'
      icon.style.fill = '#2485EA'
    } else {
      icon.style.transform = 'rotate(180deg)'
      icon.style.fill = '#f97316'
    }
  }
}
