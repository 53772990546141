/* eslint-disable vue/one-component-per-file */
/* eslint-disable vue/multi-word-component-names */
/* eslint-disable vue/match-component-file-name */

import {createApp, defineAsyncComponent} from 'vue'
import VueLazyload from 'vue-lazyload'
import Vue3TouchEvents from 'vue3-touch-events'

import {initVueResource} from '@tfx/common/config/vue-resource'

import {setupTestid} from '@tfx/common/test-helper'
import {store} from 'static/store'
import Tooltip from '@tfx/common/components/tooltip/tooltip-directive.js'
import OnlyLatinDirective from '@tfx/common/directives/only-latin-directive'
import OnlyNumberDirective from '@tfx/common/directives/only-number-directive'
import willvalidate, {changeDefaults} from '@tfx/common/directives/willvalidate'
import ModalDialogDirective from 'static/directives/modal-dialog'
import DomToComponentMixin from '@tfx/common/mixins/dom-to-component'

import HeaderPhones from 'static/components/partials/header-phones'
import HeaderAuthorization from 'static/components/partials/header-authorization'
import DeadlineCounter from 'static/components/deadline-counter'
import InputPhone from '@tfx/client/components/input-phone'

let mountedComponents
const appContext = createAppContext()

export default function () {
  store.dispatch('geoLocation/getGeoData')
  store.dispatch('authorization/getClientData')

  changeDefaults('validate-msg', 'validate-msg', 'span')

  setupTrubolinks()
}

function setupTrubolinks() {
  document.addEventListener('turbolinks:load', () => {
    initVueResource()
    mountComponents()
  })

  document.addEventListener('turbolinks:visit', () => {
    mountedComponents.forEach(c => c.unmount())
  })
}

function mountComponents() {
  mountedComponents = []

  const pageComponents = Array.from(document.querySelectorAll('[tfx-component="vue-component"]'))

  const nonBlockingMount = () => {
    if (pageComponents.length) {
      setTimeout(() => {
        const app = createAppComponent()
        app.mount(pageComponents.shift())

        mountedComponents.push(app)
        nonBlockingMount()
      }, 0)
    }
  }

  nonBlockingMount()
}

export function createAppComponent(options = {}) {
  const app = createApp(options)

  Object.assign(app._context, appContext)
  return app
}

function createAppContext() {
  const app = createApp()

  setupTestid(app)

  app.use(store)
  app.use(Vue3TouchEvents)
  app.use(willvalidate)

  app.use(VueLazyload, {
    observer: true,
    lazyImage: true
  })

  app.directive('modal-dialog', ModalDialogDirective)
  app.directive('only-latin', OnlyLatinDirective)
  app.directive('only-number', OnlyNumberDirective)
  app.directive('tooltip', Tooltip)

  app.mixin(DomToComponentMixin)

  // Header / Navigation
  //
  app.component('header-phones', HeaderPhones)
  app.component('free-online-call-dialog', defineAsyncComponent(() => import('static/components/free-online-call-dialog')))
  app.component('header-authorization', HeaderAuthorization)
  app.component('deadline-counter', DeadlineCounter)

  // Index.html
  //
  app.component('country-guide', defineAsyncComponent(() => import('static/components/partials/country-guide')))
  app.component('video-testimonials-slider', defineAsyncComponent(() => import('static/components/sliders/video-testimonials-slider')))
  app.component('reviews-slider', defineAsyncComponent(() => import('static/components/sliders/reviews-slider')))
  app.component('home-counter', defineAsyncComponent(() => import('static/components/home-counter.vue')))

  // Login
  //
  app.component('login-form', defineAsyncComponent(() => import('static/components/login-form')))
  app.component('login-mfa', defineAsyncComponent(() => import('static/components/mfa/login-mfa')))
  app.component('login-mfa-sms', defineAsyncComponent(() => import('static/components/mfa/login-mfa-sms')))
  app.component('login-refer', defineAsyncComponent(() => import('static/components/login-refer')))
  app.component('client-logout-button', defineAsyncComponent(() => import('@tfx/common/components/logout/client-logout-button')))

  // Others pages
  //
  app.component('willvalidate-error-wrapper', defineAsyncComponent(() => import('@tfx/static/components/forms/willvalidate-error-wrapper')))
  app.component('static-textarea', defineAsyncComponent(() => import('static/components/forms/static-textarea')))
  app.component('static-input', defineAsyncComponent(() => import('static/components/forms/static-input')))
  app.component('input-phone', InputPhone)
  app.component('static-input-password', defineAsyncComponent(() => import('static/components/forms/input-password')))
  app.component('reset-password-form', defineAsyncComponent(() => import('static/components/forms/reset-password-form')))

  app.component('tfx-multiselect', defineAsyncComponent(() => import('@tfx/common/components/forms/tfx-multiselect')))

  app.component('contact-us', defineAsyncComponent(() => import('static/components/contact-us')))
  app.component('bug-bounty-report-form', defineAsyncComponent(() => import('static/components/bug-bounty-report-form')))
  app.component('careers-form', defineAsyncComponent(() => import('static/components/careers-form')))

  app.component('footer-phones', defineAsyncComponent(() => import('static/components/partials/footer-phones')))
  app.component('footer-phones-landing', defineAsyncComponent(() => import('static/components/partials/footer-phones-landing')))
  app.component('local-phone', defineAsyncComponent(() => import('static/components/partials/local-phone')))
  app.component('american-educators-abroad-slider', defineAsyncComponent(() => import('static/components/sliders/american-educators-abroad-slider')))
  app.component('email-question-form', defineAsyncComponent(() => import('static/components/email-question-form')))
  app.component('tax-quiz-questionnaire', defineAsyncComponent(() => import('static/components/tax-quiz/questionnaire')))

  app.component('date-picker', defineAsyncComponent(() => import('@tfx/common/components/date-picker')))

  return {
    config: app._context.config,
    components: app._context.components,
    directives: app._context.directives,
    mixins: app._context.mixins,
    provides: app._context.provides
  }
}
