import * as Sentry from '@sentry/vue'
import {RAILS_ENV} from '../constants.js'

const TWILIO_ERRORS_WHITELIST = [
  'TransportError',
  'ConnectionError'
]

export function isSentryAvailable() {
  return ['production', 'staging'].includes(RAILS_ENV)
}

export function setSentryUserId(userId) {
  if (isSentryAvailable()) {
    console.log(`Set sentry user id to ${userId}`) // eslint-disable-line
    Sentry.setUser({id: userId})
  }
}

export function startSentry(options = {}) {
  if (isSentryAvailable()) {
    Sentry.init({
      Vue: options.vue,
      app: options.app,
      dsn: 'https://db75470cd39c49059bf30fdfc9b0fcfa@o98811.ingest.sentry.io/229335',
      environment: RAILS_ENV,
      ignoreErrors: ['ResizeObserver loop limit exceeded', ...TWILIO_ERRORS_WHITELIST]
    })
    console.info(`Sentry started for environment ${RAILS_ENV}`) // eslint-disable-line
  } else if (RAILS_ENV !== 'test') {
    console.info(`Sentry not configured for environment ${RAILS_ENV}`) // eslint-disable-line
  }
}

export default {
  install: function (vue, options) {
    startSentry({...options, vue})
  }
}
