import {showTimedTooltip} from '@/common/api/tooltips'
const regexOnlyLatin = new RegExp(/[^a-zA-Z0-9_\s~`!@#$%^&*()-=+\\|'";:\/?.>,<;]/) // eslint-disable-line
const regexExtLatin = /[^A-Za-zŽžÀ-ÖØ-ßà-öø-ÿ0-9_\s~`!@#$%^&*()-=+\\|'";:/?.>,<;]/u

function directiveBeforeMount(el, binding) {
  const handler = function (e) {
    if (binding.value && binding.value.active === false) return
    const regex = binding.value && binding.value.allowExtendedLatin ? regexExtLatin : regexOnlyLatin

    if (e.target.value.match(regex)) {
      showTimedTooltip(el, 'Invalid character', 'only-latin-char')
      e.target.value = e.target.value.replace(regex, '')
      el.dispatchEvent(new CustomEvent('input'))
    }
  }
  el.addEventListener('input', handler)
}

export default {
  beforeMount(...args) {
    directiveBeforeMount(...args)
  }
}
